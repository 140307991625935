




























































import mixins from 'vue-typed-mixins'
import DialogMixin from '@/components/dialogs/mixins/DialogMixins'
import EmployeeCarousel from '@/components/dialogs/components/BookingDialog/components/EmployeeCarousel.vue'
import Service from '@/calendesk/models/DTO/Response/Service'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import { mapActions } from 'vuex'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import BookingEvent from '@/calendesk/models/BookingEvent'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'

export default mixins(DialogMixin).extend({
  name: 'BookingDialog1',
  data () {
    return {
      selectedEmployee: null as Employee | null,
      isReady: false,
      data: null as any | null,
      configurationData: null as any | null,
      service: null as Service | null,
      serviceType: null as ServiceType | null,
      isCreatingBooking: false,
      customerTimeZone: null as string | null,
      location: null as ServiceLocation | null
    }
  },
  components: {
    EmployeeCarousel
  },
  computed: {
    getSelectedEmployee (): Employee | null {
      return this.selectedEmployee ? this.selectedEmployee : null
    },
    isSameDay (): boolean {
      if (this.data && this.data.eventParsed) {
        return this.data.eventParsed.start.date === this.data.eventParsed.end.date
      }

      return false
    }
  },
  created () {
    this.data = this.dialog.data
    if (this.data) {
      this.service = this.data.event.service
      this.serviceType = this.data.event.serviceType
      this.selectedEmployee = this.data.event.employee
      this.customerTimeZone = this.data.event.customerTimeZone
      this.location = this.data.event.location

      this.configurationData = this.dialog.data.configurationData
    }

    this.isReady = true
  },
  methods: {
    ...mapActions({
      createBooking: 'booking/create',
      setReloadAllBookings: 'booking/setReloadAllBookings'
    }),
    addToCart () {
      this.closeDialog({ data: this.data, addToCart: true, employee: this.getSelectedEmployee, location: this.location })
    },
    removeFromCart () {
      this.closeDialog({ data: this.data, removeFromCart: true, employee: this.getSelectedEmployee, location: this.location })
    },
    book () {
      this.openDialog(new Dialog(
        true,
        DialogTypes.BOOKING_FORM_DIALOG,
        null,
        DialogSize.MIDDLE,
        false,
        'center',
        {
          events: [
            new BookingEvent(
              this.service as Service,
              this.getSelectedEmployee as Employee,
              this.serviceType,
              this.data.eventParsed.start.date,
              this.data.eventParsed.start.time,
              this.data.eventParsed.end.date,
              this.data.eventParsed.end.time,
              null,
              // not supported here since selected employee is always selected,
              // this dialog is used by a weekly calendar, that should be replaced soon
              this.customerTimeZone,
              false,
              this.location)
          ],
          configurationData: this.configurationData
        },
        true))
    }
  }
})
